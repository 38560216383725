<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header
              :header-class="Object.assign(headerBG, {'pt-1':true, 'pl-1':true, 'pb-0':true, 'text-center':true})">
            <span class="flex-fill header-text"><strong>即時監測資訊</strong></span>
          </b-card-header>
          <b-row no-gutters>
            <b-col cols="2">
              <div class="d-flex flex-column justify-content-between h-100">
                <h3 class="pl-1 pt-1 text-center flex-fill">{{ weatherForecast.locationName }}</h3>
                <b-img :src="weatherIconUrl"
                       class="rounded-0 align-middle flex-fill p-1" fluid-grow/>
                <div class="align-bottom p-1">
                  <h5>{{weatherForecastStr}}</h5>
                  <h5>溫度:&nbsp;{{weatherTempStr}}</h5>
                  <h5>降雨機率:&nbsp;{{weatherPoRainStr}}%</h5>
                </div>
              </div>
            </b-col>
            <b-col cols="5">
              <h3 class="pt-1 w-100 text-center">空品即時最高濃度</h3>
              <b-table-simple bordered fixed>
                <b-tbody>
                  <b-tr v-for="mtSummary in realtimeSummary.mtSummaries" :key="mtSummary.mt">
                    <b-td class="p-1" colspan="4">{{ getMtName(mtSummary.mt) }}&nbsp;({{
                        getMtUnitName(mtSummary.mt)
                      }})
                    </b-td>
                    <b-td class="p-1" colspan="2"><h5>{{ mtSummary.max }}</h5>&nbsp;</b-td>
                    <b-td colspan="2" class="text-center p-1">
                      <b-img v-if="isValueNormal(mtSummary.mt, mtSummary.max)"
                             v-b-tooltip.hover
                             src="../assets/images/normal.png"
                             title="正常"
                             fluid-grow/>
                      <b-img v-else v-b-tooltip.hover
                             src="../assets/images/over_std.png"
                             title="超標"
                             fluid-grow/>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="p-1" colspan="4">連接狀況</b-td>
                    <b-td class="p-1" colspan="4">正常:{{ realtimeSummary.connected }}&nbsp;斷線:{{
                        realtimeSummary.disconnected
                      }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col cols="5">
              <h3 class="pt-1 w-100 text-center">灑水系統控制</h3>
              <b-table-simple bordered fixed>
                <b-tbody>
                  <b-tr>
                    <b-td><b-checkbox v-model="sprayAction" switch :disabled="!hasSpray || timer!==0"
                                      @change="toggleSpray">啟動
                    </b-checkbox></b-td>
                    <b-td><b-img v-if="hasSpray && spray" src="../assets/sprinkler_on.svg" fluid/>
                      <b-img v-else src="../assets/sprinkler_off.svg" fluid/>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><h5>系統狀態:</h5></b-td>
                    <b-td><h5>{{ sprayConnected }}</h5></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><h5>連線狀態:</h5></b-td>
                    <b-td><h5>{{ sprayStatus }}</h5></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body>
          <b-card-header
              :header-class="Object.assign(headerBG, {'pt-1':true, 'pl-1':true, 'pb-0':true, 'text-center':true})">
            <span class="flex-fill header-text"><strong>汙染物趨勢圖</strong></span>
          </b-card-header>
          <div id="historyTrend" ></div>
        </b-card>
      </b-col>
      <b-col>
        <b-card
            class="text-center"
            no-body>
          <b-card-header header-class="pt-1 pl-1 pb-0">
            <span class="flex-fill header-text text-dark"><strong>即時空氣品質監測</strong></span>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                  id="radio-group-1"
                  v-model="mapMonitorType"
                  :options="myMonitorTypesOptions"
                  :aria-describedby="ariaDescribedby"
                  class="mb-0 text-dark"
                  name="radio-options"
              ></b-form-radio-group>
            </b-form-group>
          </b-card-header>
          <div class="map_container">
            <div id="mapLegend" class="mb-2 rounded">
              <b-img v-if="mapMonitorType==='PM25'" src="../assets/images/pm25_legend.png" width="400" fluid
                     class="float-right"/>
              <b-img v-else-if="mapMonitorType==='PM10'" src="../assets/images/pm10_legend.png" width="400" fluid
                     class="float-right"/>
            </div>
            <div id="mapFilter" class="mt-2 ml-2">
              <b-form-group>
                <b-form-checkbox v-model="showSensor">感測器</b-form-checkbox>
                <b-form-checkbox v-model="showEpaMonitor">環境部</b-form-checkbox>
                <b-form-checkbox v-model="showCamera">攝影機</b-form-checkbox>
              </b-form-group>
            </div>
            <GmapMap
                ref="mapRef"
                :center="mapCenter"
                :zoom="13"
                map-type-id="terrain"
                class="map_canvas"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUi: false
                }"
            >
              <div v-if="mapLoaded">
                <GmapMarker
                    v-for="(m, index) in markers"
                    :key="index"
                    :position="m.position"
                    :clickable="true"
                    :title="m.title"
                    :label="{
                  text: m.label,
                  className: 'map-label bg-white rounded border border-primary',
                  color: 'black',
                  fontSize: '12px',
                  fontWeight: '400',
                }"
                    :icon="m.icon"
                    @click="toggleInfoWindow(m, index)"
                />
                <div v-if="showCamera">
                  <GmapMarker
                      v-for="(m, index) in cameraMarkers"

                      :key="`camera_${index}`"
                      :position="m.position"
                      :clickable="true"
                      :title="m.title"
                      :icon="m.icon"
                  ></GmapMarker>
                </div>
                <gmap-info-window
                    :options="infoOptions"
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="infoWinOpen = false"
                />
              </div>
            </GmapMap>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="d-flex flex-column">
          <b-card no-body>
            <b-card-header
                :header-class="Object.assign(headerBG, {'pt-1':true, 'pl-1':true, 'pb-0':true, 'text-center':true})">
              <span class="flex-fill header-text"><strong>最新影像資訊</strong></span>
            </b-card-header>
            <b-row v-if="latestImages.length !== 0" no-gutters>
              <b-col
                  v-for="imageAction in latestImages"
                  :key="imageAction.image._id"
                  :cols="imageCols"
                  class="text-center"
              >
                <b-img
                    :src="getImageUrl(imageAction.image._id)"
                    fluid
                    thumbnail
                />
                <h3>{{imageAction.image.topic}}:
                  <strong v-if="imageAction.trigger" class="text-danger">事件發生</strong>
                  <strong v-else class="text-success">正常</strong>
                </h3>
              </b-col>
            </b-row>
            <b-row v-else no-gutters>
              <b-col>
                <b-img src="../assets/images/no_image.jpg" fluid thumbnail/>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
      <b-col>
        <b-card no-body>
          <b-card-header
              :header-class="Object.assign(headerBG, {'pt-1':true, 'pl-1':true, 'pb-0':true, 'text-center':true})">
            <span class="flex-fill header-text"><strong>過去24小時警報</strong></span>
          </b-card-header>
          <b-table striped hover :fields="alarmColumns" :items="alarms"/>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.legend {
  /* min-width: 100px;*/
  background-color: white;
}

.map-label {
  margin-top: 3rem !important;
  padding: 0.2rem !important;
}

.top-control {
  max-height: 80vh;
}
.header-bg-color {
  background: linear-gradient(118deg, #528aa2, rgba(21, 96, 130, 0.7));
  color: lightgrey;
}

.header-text {
  font-size: 1.286rem;
  margin-block-start: 0;
  margin-block-end: 0.4em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  color: lightgrey;
}

.custom-control-label {
  color: lightgrey !important;
}

.header-bg-color-dark {
  background: linear-gradient(118deg, darkolivegreen, rgba(21, 96, 130, 0.7));
}

.sensorFilter {
  background-color: white;
}

.title {
  font-size: 1.5rem;
  font-weight: bolder;
}

#mapFilter {
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}
</style>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import axios from 'axios';
import highcharts from 'highcharts';
import useAppConfig from '@core/app-config/useAppConfig';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

export default {
  data() {
    const range = [moment().subtract(1, 'days').valueOf(), moment().valueOf()];
    let group = undefined;
    let realtimeSummary = {
      mtSummaries: [],
      connected: 0,
      disconnected: 0,
    };
    let latestImages = [];
    let alarmColumns = [
      {
        key: 'time',
        label: '時間',
        sortable: true,
      },
      {
        key: 'level',
        label: '等級',
        sortable: true,
        formatter: value => {
          switch (value) {
            case 1:
              return '資訊';
            case 2:
              return '警告';
            case 3:
              return '嚴重';
            default:
              return '未知';
          }
        },
      },
      {
        key: 'subSrc',
        label: '來源',
        sortable: true,
      },
      {
        key: 'desc',
        label: '詳細資訊',
        sortable: true,
      },
    ];

    const mapLayerTypes = [
      {
        txt: '感測器',
        value: 'sensor',
      },
      {
        txt: '環境部',
        value: 'EPA',
      },
      {
        txt: '攝影機',
        value: 'Camera',
      },
    ];
    return {
      dataTypes: [{ txt: '分鐘資料', id: 'min' }],
      form: {
        monitors: [],
        dataType: 'min',
        range,
      },
      columns: [],
      rows: [],
      realTimeStatus: [],
      hasSpray: false,
      spray: false,
      spray_connected: false,
      loader: undefined,
      timer: 0,
      countdown: 0,
      refreshTimer: 0,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      mapLoaded: false,
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      group,
      mapMonitorType: 'PM25',
      realtimeSummary,
      latestImages,
      sprayAction: false,
      showEpaMonitor: false,
      showSensor: true,
      showCamera: true,
      cameraList: [],
      alarmColumns,
      alarms: [],
      weatherForecast: {},
    };
  },
  computed: {
    ...mapState('monitorTypes', ['monitorTypes']),
    ...mapState('monitors', ['monitors']),
    ...mapState('user', ['userInfo']),
    ...mapGetters('monitorTypes', ['mtMap']),
    ...mapGetters('monitors', ['mMap']),
    isDark() {
      const { skin } = useAppConfig();
      return skin.value === 'dark';
    },
    headerBG() {
      return {
        'header-bg-color': !this.isDark,
        'header-bg-color-dark': this.isDark,
      };
    },
    imageCols() {
      if (this.latestImages.length === 0) return 12;
      if (this.latestImages.length === 1) return 12;
      if (this.latestImages.length === 2) return 6;
      if (this.latestImages.length >= 3) return 4;

      return 4;
    },
    weatherForecastStr() {
      if (this.weatherForecast.locationName) {
        let wx = this.weatherForecast.weatherElement.find(
            v => v.elementName === 'Wx',
        );
        if (wx) {
          return wx.time[0].parameter.parameterName;
        }
      }
      return '';
    },
    weatherIconUrl() {
      if (this.weatherForecast.locationName) {
        let wx = this.weatherForecast.weatherElement.find(
          v => v.elementName === 'Wx',
        );

        if (wx) {
          let now = new moment();
          let hour = now.hour();
          let name = ("00" + wx.time[0].parameter.parameterValue).slice(-2);
          if (hour >= 18 || hour < 6) {
            return `https://www.cwa.gov.tw/V8/assets/img/weather_icons/weathers/svg_icon/night/${name}.svg`;
          } else {
            return `https://www.cwa.gov.tw/V8/assets/img/weather_icons/weathers/svg_icon/day/${name}.svg`;
          }
        }
      }
      return '';
    },
    weatherPoRainStr(){
      if (this.weatherForecast.locationName) {
        let wx = this.weatherForecast.weatherElement.find(
            v => v.elementName === 'PoP',
        );

        if (wx) {
          return wx.time[0].parameter.parameterName;
        }
      }
      return '';
    },
    weatherTempStr(){
      if (this.weatherForecast.locationName) {
        let minT = this.weatherForecast.weatherElement.find(
            v => v.elementName === 'MinT',
        );

        let maxT = this.weatherForecast.weatherElement.find(
            v => v.elementName === 'MaxT',
        );

        if (minT && maxT) {
          return `${minT.time[0].parameter.parameterName}~${maxT.time[0].parameter.parameterName} ℃`;
        }
      }
      return '';
    },
    myMonitorTypes() {
      const defaultMonitorTypes = ['PM10', 'PM25'];
      if (this.monitorTypes === undefined) return defaultMonitorTypes;
      return defaultMonitorTypes.filter(mt => this.mtMap.get(mt) !== undefined);
    },
    myMonitorTypesOptions() {
      return this.myMonitorTypes.map(mt => {
        return { value: mt, text: this.mtMap.get(mt).desp };
      });
    },
    sprayStatus() {
      if (!this.hasSpray) return '未安裝';
      if (!this.spray_connected) return '未知';
      if (this.spray) return '否';
      else return '是';
    },
    sprayConnected() {
      if (!this.hasSpray) return '未安裝';
      if (this.spray_connected) return '正常';
      else return '斷線';
    },
    mapCenter() {
      let count = 0,
        latMax = -1,
        latMin = 1000,
        lngMax = -1,
        lngMin = 1000;

      for (const stat of this.realTimeStatus) {
        const monitor = this.mMap.get(stat._id.monitor);
        let lat = monitor.location[0];
        let lng = monitor.location[1];

        if (latMin > lat) latMin = lat;
        if (latMax < lat) latMax = lat;
        if (lngMin > lng) lngMin = lng;
        if (lngMax < lng) lngMax = lng;
        count++;
      }

      if (count === 0) return { lat: 23.9534736767587, lng: 120.9682970796872 };

      let lat = (latMax + latMin) / 2;
      let lng = (lngMax + lngMin) / 2;
      return { lat, lng };
    },
    markers() {
      const ret = [];

      const getMtUrl = mtEntries => {
        let valueStr = '';
        let valueStrList = [];
        let v = 0;
        for (let mtEntry of mtEntries) {
          let mt = mtEntry.mt;
          let mtCase = this.mtMap.get(mt);
          if (mtEntry.data.value) {
            valueStrList.push(
              `${mtCase.desp}:${mtEntry.data.value.toFixed(mtCase.prec)}`,
            );
            if (mt === this.mapMonitorType) v = mtEntry.data.value;
          }
        }
        valueStr = valueStrList.join(', ');

        let fillColor;
        if (this.mapMonitorType === 'PM25') {
          if (v < 15) fillColor = `#009865`;
          else if (v < 35) fillColor = `#FFFB26`;
          else if (v < 54) fillColor = `#FF9835`;
          else if (v < 150) fillColor = `#CA0034`;
          else if (v < 250) fillColor = `#670099`;
          else if (v < 350) fillColor = `#7E0123`;
          else fillColor = `#7E0123`;
        } else {
          // PM10
          if (v < 50) fillColor = `#009865`;
          else if (v < 100) fillColor = `#FFFB26`;
          else if (v < 254) fillColor = `#FF9835`;
          else if (v < 354) fillColor = `#CA0034`;
          else if (v < 424) fillColor = `#670099`;
          else if (v < 504) fillColor = `#7E0123`;
          else fillColor = `#7E0123`;
        }

        let markerIcon = {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor,
          fillOpacity: 1,
          scale: 12,
          strokeColor: 'white',
          strokeWeight: 0.5,
        };

        let pm25desc = '';
        return {
          pm25desc,
          markerIcon,
          valueStr,
        };
      };

      for (const stat of this.realTimeStatus) {
        let pm25 = 0;

        let mtEntries = this.userInfo.monitorTypeOfInterest.flatMap(mt => {
          const data = stat.mtDataList.find(v => v.mtName === mt);
          if (!data) return [];

          return [
            {
              mt,
              data,
            },
          ];
        });

        const { markerIcon, valueStr, pm25desc } = getMtUrl(mtEntries);

        const monitor = this.mMap.get(stat._id.monitor);
        if (!monitor) continue;

        if (monitor.tag === 'CWA') continue;

        if (monitor.tag === 'EPA' && this.showEpaMonitor === false) continue;

        if (!monitor.tag && this.showSensor === false) continue;

        let label = pm25desc
          ? `${monitor.desc}-${pm25desc}`
          : `${monitor.desc}`;

        let lat = monitor.location[0];
        let lng = monitor.location[1];
        ret.push({
          title: valueStr,
          position: { lat, lng },
          pm25,
          infoText: `<strong>${monitor.desc}</strong>`,
          label,
          icon: markerIcon,
        });
      }

      // auto fit the map
      /*
      const ref = this.$refs.mapRef;
      if (ref) {
        ref.$mapPromise.then(map => {
          let bounds = new google.maps.LatLngBounds();
          for (let marker of ret) {
            bounds.extend(marker.position);
          }
          let mapDim = {
            height: map.getDiv().clientHeight,
            width: map.getDiv().clientWidth,
          };
          map.fitBounds(bounds);
          map.setZoom(this.getBoundsZoomLevel(bounds, mapDim));
        });
      }
       */
      return ret;
    },
    cameraMarkers() {
      const ret = [];

      for (const camera of this.cameraList) {
        if (camera.location && camera.location.length === 2) {
          let lat = camera.location[0];
          let lng = camera.location[1];
          ret.push({
            title: camera.name,
            position: { lat, lng },
            infoText: `<strong>${camera.name}</strong>`,
            label: camera.name,
            icon: {
              path: faCamera.icon[4],
              fillColor: 'blue',
              fillOpacity: 1,
              anchor: new google.maps.Point(
                faCamera.icon[0] / 2, // width
                faCamera.icon[1], // height
              ),
              strokeWeight: 1,
              strokeColor: '#ffffff',
              scale: 0.04,
            },
          });
        }
      }

      return ret;
    },
  },
  watch: {
    mapMonitorType() {
      this.drawAllTrendChart();
    },
  },
  async mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      this.mapLoaded = true;
      const mapLegend = document.getElementById('mapLegend');
      const mapFilter = document.getElementById('mapFilter');
      if (mapLegend !== null) {
        const ref = this.$refs.mapRef;
        ref.$mapPromise.then(map => {
          map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(mapLegend);
          map.controls[google.maps.ControlPosition.LEFT_CENTER].push(mapFilter);
        });
      }
    });

    await this.getGroupDoInstrumentList();
    this.refresh();
    this.refreshTimer = setInterval(() => {
      this.refresh();
    }, 60000);
    await this.fetchMonitors();
    await this.fetchMonitorTypes();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.refreshTimer);
  },
  methods: {
    ...mapActions('monitorTypes', ['fetchMonitorTypes']),
    ...mapActions('monitors', ['fetchMonitors']),
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    async getLatestImages() {
      let ret = await axios.get('/LatestImages');
      if (ret.status === 200) {
        this.latestImages = ret.data;
      }
    },
    async refresh() {
      await this.fetchMonitorTypes();
      await this.fetchMonitors();
      await this.getCameraList();
      await this.getLatestImages();
      await this.getAlarmLast24H();
      await this.getWeatherForecast();
      await this.getMyGroup();
      if (this.group) {
        this.form.monitors = this.group.monitors;
        this.form.monitorTypes = this.group.monitorTypes;
      } else {
        if (this.monitorTypes.length !== 0) {
          this.form.monitorTypes = [];
          this.form.monitorTypes.push(this.monitorTypes[0]._id);
        }

        if (this.monitors.length !== 0) {
          this.form.monitors = [];
          for (const m of this.monitors) this.form.monitors.push(m._id);
        }
      }

      await this.query();
      await this.drawAllTrendChart();
      await this.getRealtimeSummary();
      await this.getRealtimeStatus();
      await this.getSignalValues();
    },
    async getGroupDoInstrumentList() {
      const res = await axios.get('/MyGroupDoInstrument');
      if (res.data.length === 0) this.hasSpray = false;
      else this.hasSpray = true;
    },
    async query() {
      this.rows.splice(0, this.rows.length);
      this.columns = this.getColumns();
      const monitors = this.form.monitors.join(':');
      const monitorTypes = this.userInfo.monitorTypeOfInterest.join(':');
      const url = `/LatestData/${monitors}/${monitorTypes}/${this.form.dataType}`;

      const ret = await axios.get(url);
      for (const row of ret.data.rows) {
        row.date = moment(row.date).format('MM-DD HH:mm');
      }

      this.rows = ret.data.rows;
    },
    async drawTrendChart(mt) {
      let now = new Date().getTime();

      const oneHourBefore = now - 60 * 60 * 1000;
      const myMonitors = this.monitors
        .filter(m => m.tag !== 'CWA')
        .map(m => m._id)
        .join(':');
      //const url = `/HistoryTrend/${myMonitors}/${mt}/all/Min/normal/${oneHourBefore}/${now}`;
      const url = `/HistoryTrend/${myMonitors}/${mt}/Min/normal/${oneHourBefore}/${now}`;
      const res = await axios.get(url);
      const ret = res.data;

      ret.chart = {
        type: 'spline',
        zoomType: 'x',
        panning: {
          enabled: true,
        },
        panKey: 'shift',
        alignTicks: false,
      };

      const pointFormatter = function pointFormatter() {
        const d = new Date(this.x);
        return `${d.toLocaleString()}:${Math.round(this.y)}度`;
      };

      let mtInfo = this.mtMap.get(mt);
      ret.title.text = '';
      ret.tooltip = { valueDecimals: 2 };
      ret.legend = { enabled: true };
      ret.credits = {
        enabled: false,
        href: 'http://www.wecc.com.tw/',
      };

      ret.tooltip = { valueDecimals: 2 };
      ret.legend = { enabled: true };
      ret.credits = {
        enabled: false,
        href: 'http://www.wecc.com.tw/',
      };
      ret.xAxis.type = 'datetime';
      ret.xAxis.dateTimeLabelFormats = {
        day: '%b%e日',
        week: '%b%e日',
        month: '%y年%b',
      };

      ret.plotOptions = {
        scatter: {
          tooltip: {
            pointFormatter,
          },
        },
      };
      ret.time = {
        timezoneOffset: -480,
      };
      ret.exporting = {
        enabled: false,
      };
      highcharts.chart(`history_${mt}`, ret);
    },
    async drawAllTrendChart() {
      let now = new Date().getTime();

      const oneHourBefore = now - 60 * 60 * 1000;
      const myMonitors = this.monitors
        .filter(m => m.tag !== 'CWA')
        .map(m => m._id)
        .join(':');

      const url = `/HistoryTrend/${myMonitors}/${this.mapMonitorType}/Min/normal/${oneHourBefore}/${now}`;
      const res = await axios.get(url);
      const ret = res.data;

      ret.chart = {
        type: 'spline',
        zoomType: 'x',
        panning: {
          enabled: true,
        },
        panKey: 'shift',
        alignTicks: false,
      };

      const pointFormatter = function pointFormatter() {
        const d = new Date(this.x);
        return `${d.toLocaleString()}:${Math.round(this.y)}度`;
      };

      ret.title.text = '';
      ret.tooltip = { valueDecimals: 2 };
      ret.legend = { enabled: true };
      ret.credits = {
        enabled: false,
        href: 'http://www.wecc.com.tw/',
      };

      ret.tooltip = { valueDecimals: 2 };
      ret.legend = { enabled: true };
      ret.credits = {
        enabled: false,
        href: 'http://www.wecc.com.tw/',
      };
      ret.xAxis.type = 'datetime';
      ret.xAxis.dateTimeLabelFormats = {
        day: '%b%e日',
        week: '%b%e日',
        month: '%y年%b',
      };

      ret.plotOptions = {
        scatter: {
          tooltip: {
            pointFormatter,
          },
        },
      };
      ret.time = {
        timezoneOffset: -480,
      };
      ret.exporting = {
        enabled: false,
      };
      highcharts.chart(`historyTrend`, ret);
    },
    async getRealtimeStatus() {
      try {
        const ret = await axios.get('/RealtimeStatus');
        this.realTimeStatus = ret.data;
      } catch (ex) {
        throw new Error('failed');
      }
    },
    async getRealtimeSummary() {
      try {
        const res = await axios.get('/RealtimeSummary');
        this.realtimeSummary = res.data;
      } catch (ex) {
        throw new Error('failed');
      }
    },
    cellDataTd(i) {
      return (_value, _key, item) => item.cellData[i].cellClassName;
    },
    getMtDesc(mt) {
      if (this.mtMap.get(mt)) {
        const mtCase = this.mtMap.get(mt);
        return `${mtCase.desp}(${mtCase.unit})`;
      } else return '';
    },
    getColumns() {
      const ret = [];
      ret.push({
        key: 'date',
        label: '時間',
      });
      let i = 0;
      for (const mt of this.userInfo.monitorTypeOfInterest) {
        for (const m of this.form.monitors) {
          const mCase = this.mMap.get(m);
          ret.push({
            key: `cellData[${i}].v`,
            label: `${mCase.desc}`,
            tdClass: this.cellDataTd(i),
          });
          i++;
        }
      }

      return ret;
    },
    async getSignalValues() {
      const res = await axios.get('/SignalValues');
      this.spray = res.data.SPRAY === true;
      this.spray_connected = res.data.SPRAY !== undefined;
    },
    async testSpray() {
      await axios.get('/TestSpray');
      this.countdown = 5 * 6;
      this.timer = setInterval(() => {
        this.countdown--;
        this.getSignalValues();
        if (this.countdown === 0) {
          clearInterval(this.timer);
          this.timer = 0;
        }
      }, 1000);
    },
    async toggleSpray() {
      if (this.sprayAction) {
        await this.testSpray();
      } else {
        await this.testSpray();
      }
    },
    getBoundsZoomLevel(bounds, mapDim) {
      var WORLD_DIM = { height: 256, width: 256 };
      var ZOOM_MAX = 21;

      function latRad(lat) {
        var sin = Math.sin((lat * Math.PI) / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }

      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();

      var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

      var lngDiff = ne.lng() - sw.lng();
      var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

      var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
      var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

      return Math.min(latZoom, lngZoom, ZOOM_MAX);
    },
    async getMyGroup() {
      let ret = await axios.get('/Group');
      if (ret.status === 200) {
        this.group = ret.data;
      }
    },
    isValueNormal(mt, value) {
      if (this.mtMap === undefined) return true;

      let mtCase = this.mtMap.get(mt);
      if (mtCase === undefined) return true;

      let std = mtCase.std_law;
      if (value === undefined || std === undefined || value <= std) return true;

      console.info(`mt=${mt} value=${value}, std=${std}`);
      return false;
    },
    getMtName(mt) {
      const mtCase = this.mtMap.get(mt);
      if (mtCase === undefined) return '';
      return this.mtMap.get(mt).desp;
    },
    getMtUnitName(mt) {
      const mtCase = this.mtMap.get(mt);
      if (mtCase === undefined) return '';
      return this.mtMap.get(mt).unit;
    },
    getImageUrl(id) {
      if (!id) return '';

      const baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:9000/' : '/';

      return `${baseUrl}Image/${id}`;
    },
    async getCameraList() {
      try {
        const res = await axios.get('/Cameras');
        if (res.status === 200) {
          this.cameraList = res.data;
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    async getAlarmLast24H() {
      try {
        const range = [
          moment().subtract(1, 'days').valueOf(),
          moment().valueOf(),
        ];
        const url = `/AlarmReport/2/${range[0]}/${range[1]}`;
        const res = await axios.get(url);
        const ret = res.data;
        for (const alarm of ret) {
          alarm.time = moment(alarm.time).format('lll');
          const src = alarm.src.split(':');
          alarm.src = src[1];
        }
        this.alarms = ret;
      } catch (ex) {
        console.error(ex);
      }
    },
    async getWeatherForecast() {
      try {
        const res = await axios.get('/WeatherForecast');
        if (res.status === 200) {
          console.log(res.data);
          this.weatherForecast = res.data[0];
        }
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};
</script>
